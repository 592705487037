export default {
    template: /*html*/`
    <svg xmlns="http://www.w3.org/2000/svg" width="21.35" height="25" viewBox="0 0 21.35 25">
        <g id="Raggruppa_511" data-name="Raggruppa 511" transform="translate(-1518 -45)">
            <path id="Tracciato_152" data-name="Tracciato 152" d="M757.856,284a1.1,1.1,0,0,1,.842,1.245c-.018.249-.09.564.042.733.116.149.482.132.737.194a7.038,7.038,0,0,1,5.567,5.845,17.1,17.1,0,0,1,.057,1.757c.02.845-.025,1.7.07,2.533a6.029,6.029,0,0,0,2.277,4.024,2.008,2.008,0,0,1,.819,1.164,1.728,1.728,0,0,1-1.648,1.994,3.19,3.19,0,0,1-.349.016h-17.29a1.919,1.919,0,0,1-1.893-1.067,1.584,1.584,0,0,1,.527-1.946,6.821,6.821,0,0,0,1.965-2.445,5.872,5.872,0,0,0,.565-2.418c.012-.906,0-1.813,0-2.719,0-3.487,2.538-6.25,6.3-6.864a.97.97,0,0,0,.105-.034c0-.26.016-.532,0-.8a1.075,1.075,0,0,1,.854-1.21Z" transform="translate(771.058 -239)" fill="#707070"/>
            <path id="Tracciato_153" data-name="Tracciato 153" d="M886.472,736.289a12.774,12.774,0,0,1-1.934-.658,4.977,4.977,0,0,1-2.58-3.455h10.084a4.3,4.3,0,0,1-.666,1.684,5.081,5.081,0,0,1-3.659,2.377.81.81,0,0,0-.148.053Z" transform="translate(641.674 -666.289)" fill="#707070"/>
        </g>
    </svg>
    `
}
