export default {
    props: ['href', 'text', 'className'],
    methods: {
        onClick: function(event) {
            event.preventDefault();

            const item = this.$refs.item;
            if (item.classList.contains('v-state')) {
                window.app.changeState(this.href);
            }
            else if (item.classList.contains('v-state-back')) {
                if (window.app.state == null) {
                    window.app.navigate(this.href);
                }

                window.app.changeState("");
            }
            else {
                app.navigate(this.href);
            }
        }
    },
    template: /*html*/`
        <a ref="item" :href="href" :class="className" @click="onClick">
            <slot></slot>
        </a>
    `
}