export default {
    name: 'modal',
    props: ['id', 'show'],
    data() {
        return {
            showModal: this.show,
        }
    },
    watch: {
        show: function(val) {
            this.showModal = val;
        }
    },
    methods: {
        close: function() {
            this.showModal = false;

            this.$emit('close');
        }
    },
    template: /*html*/`
        <div>
            <div class="modal-overlay" :class="{ show: showModal }" @click="close"></div>
            <div class="modal" :class="{ show: showModal }" :id="id">
                <div class="modal__inner">
                    <slot></slot>
                </div>
            </div>
        </div>
    `,
}