export default {
    props: ['name', 'label', 'value', 'placeholder', 'type', 'required', 'disabled', 'readonly', 'autocomplete', 'autofocus', 'maxlength', 'minlength', 'pattern', 'size', 'step', 'error', 'rows', 'className'],
    template: /*html*/`
        <div class="flex flex-col">
            <label :for="name" class="mb-2 text-sm font-medium text-gray-700" v-if="label">{{ label }}</label>
            <input v-if="type !== 'textarea'"
                :name="name"
                :id="name"
                :value="value"
                :placeholder="placeholder"
                :type="type"
                :required="required"
                :disabled="disabled"
                :readonly="readonly"
                :autocomplete="autocomplete"
                :autofocus="autofocus"
                :maxlength="maxlength"
                :minlength="minlength"
                :pattern="pattern"
                :size="size"
                :step="step"
                class="input-text"
                :class="className" />
            <textarea v-if="type === 'textarea'"
                :name="name"
                :id="name"
                :placeholder="placeholder"
                :required="required"
                :disabled="disabled"
                :readonly="readonly"
                :autocomplete="autocomplete"
                :autofocus="autofocus"
                :maxlength="maxlength"
                :minlength="minlength"
                :pattern="pattern"
                :size="size"
                :step="step"
                :rows="rows"
                class="input-text"
                :class="className">{{ value }}</textarea>
        </div>
    `
}