export default {
    name: 'not-found',
    data() {
        return {
            // ...
        }
    },
    created() {
        console.log('not found created');
    },
    template: /*html*/`
        <h1>404</h1>
        <h2>Page not found</h2>
    `
}