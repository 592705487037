import axios from 'axios';
import XLink from '../components/link';
import XForm from '../components/form';
import InputFile from '../components/input-files';
import XButton from '../components/button';
import XLoader from '../components/loader';
import XModal from '../components/modal';
import ListBox from '../components/listbox';
import ListBoxItem from '../components/listbox-item';
import ListFile from '../components/listfile';
import ListFileItem from '../components/listfile-item';

export default {
    name: 'interventi-dettaglio',
    components: {
        XLink,
        XForm,
        InputFile,
        XButton,
        XLoader,
        XModal,
        ListBox,
        ListBoxItem,
        ListFile,
        ListFileItem,
    },
    data() {
        return {
            props: {},
            data: {},
            loader: false,
            backUrl: '/interventi-richiesti',
            modal: {
                id: 'modal-interventi-dettaglio',
                show: false,
                message: '',
            },
            modal_file: {
                id: 'modal-interventi-file-dettaglio',
                show: false,
                message: '',
            },
        }
    },
    created() {
        this.props = getUrlParams();

        if (this.props.state == 10 || this.props.state == 1) {
            this.backUrl = '/interventi-richiesti';
        }
        else if (this.props.state == 4) {
            this.backUrl = '/interventi-realizzati';
        }
        else if (this.props.state == 5) {
            this.backUrl = '/interventi-storico';
        }
    },
    mounted() {
        this.loader = true;

        axios({
            method: 'get',
            url: '/api/interventi/detail',
            params: {
                id: this.props.id,
                state: this.props.state,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            this.data = response.data;

            const dataChiamata = this.data.dataChiamata;
            if (dataChiamata) {
                // Convert date from Y-m-d H:i:s to d/m/Y H:i
                this.data.dataChiamata = dataChiamata.substr(8, 2) + '/' + dataChiamata.substr(5, 2) + '/' + dataChiamata.substr(0, 4) + ' ' + dataChiamata.substr(11, 5);
            }

            const dataAppuntamento = this.data.dataAppuntamento;
            if (dataAppuntamento) {
                // Convert date from Y-m-d H:i:s to d/m/Y H:i
                this.data.dataAppuntamento = dataAppuntamento.substr(8, 2) + '/' + dataAppuntamento.substr(5, 2) + '/' + dataAppuntamento.substr(0, 4) + ' ' + dataAppuntamento.substr(11, 5);
            }

            const dataEsecuzione = this.data.dataEsecuzione;
            if (dataEsecuzione) {
                // Convert date from Y-m-d H:i:s to d/m/Y H:i
                this.data.dataEsecuzione = dataEsecuzione.substr(8, 2) + '/' + dataEsecuzione.substr(5, 2) + '/' + dataEsecuzione.substr(0, 4) + ' ' + dataEsecuzione.substr(11, 5);
            }

            this.loader = false;
        })
        .catch((error) => {
            console.log(error);

            this.loader = false;
        });
    },
    methods: {
        onSubmit: function({ e, elements }) {
            this.loader = true;
        },
        onSuccess: function({ response }) {
            this.loader = false;

            const data = response.data;

            this.modal.message = data.message;
            this.modal.show = true;
            this.isSuccess = data.response;
        },
        onSuccessFile: function({ response }) {
            this.loader = false;

            const data = response.data;

            this.modal_file.message = data.message;
            this.modal_file.show = true;
            this.isSuccess = data.response;
        },
        onError: function({ error }) {
            this.loader = false;

            this.modal.message = error.message;
            this.modal.show = true;
            this.isSuccess = false;
        },
        onModalClose: function() {
            this.modal.show = false;
        },
        onModalCloseFile: function() {
            this.modal_file.show = false;

            if (this.isSuccess) {
                window.location.reload();
            }
        },
    },
    template: /*html*/`
        <div>
            <div class="mb-12 py-4">
                <h1>
                    <x-link :href="backUrl" class="v-link">INTERVENTI</x-link> > <b>DETTAGLIO</b>
                </h1>
            </div>

            <div class="mb-12">
                <div v-if="data.codLavoro">
                    <list-box>
                        <list-box-item 
                            v-if="data.CodiceIntervento"
                            title="Codice intervento"
                            :value="data.CodiceIntervento" />

                        <list-box-item 
                            title="Residenza"
                            :value="data.luogo.Nome" />

                        <list-box-item 
                            title="Indirizzo"
                            :value="data.luogo.Indirizzo" />

                        <list-box-item 
                            title="Luogo"
                            :value="data.Dove" />

                        <list-box-item 
                            title="Urgenza"
                            :value="data.urgenzaString" />

                        <list-box-item 
                            title="Descrizione"
                            :value="data.Cosa" />
                    </list-box>

                    <list-box class="mt-10">
                        <list-box-item
                            title="Data richiesta"
                            :value="data.dataChiamata" />

                        <list-box-item
                            title="Data appuntamento"
                            :value="data.dataAppuntamento" />

                        <list-box-item
                            title="Data esecuzione"
                            :value="data.dataEsecuzione" />

                        <list-box-item
                            title="Stato"
                            :value="data.statoString" />

                        <list-box-item
                            title="Esito"
                            :value="data.Esito" />

                    </list-box>
                </div>
            </div>

            <div ref="form" v-if="data.codLavoro && data.stato != 10">
                <x-form :action="'/api/interventi/file/' + data.codLavoro" method="post" v-on:form-submit="onSubmit" v-on:success="onSuccessFile" v-on:error="onError" enctype="multipart/form-data">
                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                            <div class="form-group" data-title="Allegati">

                                <input-file name="allegati[]"
                                    label="Carica un file"
                                    className="small"
                                    required="true" />

                                <x-button color="primary"
                                    type="submit">Carica file</x-button>

                            </div>
                        </div>
                    </div>
                </x-form>
            </div>

            <div class="mb-12" v-if="data.codLavoro && data.Files.length > 0 && data.stato != 10">
                <div>
                    <list-file>
                        <div v-for="allegato in data.Files">
                            <list-file-item
                                :title="allegato.Descrizione"
                                :link="allegato.Url" />
                        </div>
                    </list-file>
                </div>
            </div>

            <x-modal :id="modal.id" :show="modal.show" v-on:close="onModalClose">
                <div class="flex flex-col items-center justify-center">
                    <p class="text-center">{{ modal.message }}</p>

                    <div class="mt-8">
                        <x-button color="primary" class="min-w-[15rem]" v-on:click="onModalClose">Ok</x-button>
                    </div>
                </div>
            </x-modal>
            <x-modal :id="modal_file.id" :show="modal_file.show" v-on:close="onModalCloseFile">
                <div class="flex flex-col items-center justify-center">
                    <p class="text-center">{{ modal_file.message }}</p>

                    <div class="mt-8">
                        <x-button color="primary" class="min-w-[15rem]" v-on:click="onModalCloseFile">Ok</x-button>
                    </div>
                </div>
            </x-modal>
            <x-loader :show="loader"></x-loader>
        </div>
    `,
}