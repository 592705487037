import dataTable from '../components/datatable';
import XLink from '../components/link';

export default {
    name: 'interventi-richiesti',
    components: {
        dataTable,
        XLink,
    },
    data() {
        return {
            data: [],
            columnsInterventi: [
                "ID",
                "Dati personali",
                "Condominio",
                "Indirizzo",
                "Data",
                "Stato",
            ],
            columnDefsInterventi: [
                {
                    targets: 0,
                    visible: false,
                },
                {
                    targets: 1,
                    render: function(data, type, row) {
                        return `<a href="/interventi/dettaglio?id=${row[0]}&state=${row[5].id}" class="v-state link">${data}</a>`;
                    },
                },
                {
                    targets: 5,
                    render: function(data, type, row) {
                        if (data.id == 10) {
                            return `<span class="text-danger-400">${data.label}</span>`;
                        }
                        else if (data.id == 1) {
                            return `<span class="text-warning-400">${data.label}</span>`;
                        }
                        else if (data.id == 4) {
                            return `<span class="text-info-400">${data.label}</span>`;
                        }
                        else if (data.id == 5) {
                            return `<span class="text-success-400">${data.label}</span>`;
                        }
                        else {
                            return `<span>${data.label}</span>`;
                        }
                    },
                },
                {
                    targets: -1,
                    data: null,
                    sortable: false,
                    searchable: false,
                    render: function(data, type, row) {
                        return `<a href="/interventi/dettaglio?id=${row[0]}&state=${row[5].id}" class="v-state button success">Vedi</a>`;
                    }
                },
            ],
        }
    },
    created() {
        
    },
    mounted() {
        
    },
    template: /*html*/`
        <div>
            <div class="flex items-center justify-between mb-6">
                <h1>INTERVENTI RICHIESTI</h1>
                <x-link href="/interventi-richiesti/nuovo" class="v-state button info">+ Nuovo</x-link>
            </div>

            <data-table ajax="/api/interventi/richiesti" :columns="columnsInterventi" :columnDefs="columnDefsInterventi"></data-table>
        </div>
    `
}