import dataTable from '../components/datatable';
import XLink from '../components/link';

export default {
    name: 'residenze',
    components: {
        dataTable,
        XLink,
    },
    data() {
        return {
            data: [],
            columnsResidenze: [
                "ID",
                "Dati personali",
                "Indirizzo",
                "Codice fiscale",
            ],
            columnDefs: [
                {
                    targets: 0,
                    visible: false,
                },
                {
                    targets: 1,
                    render: function(data, type, row) {
                        return `<a href="/residenze/dettaglio?id=${row[0]}" class="v-state link">${data}</a>`;
                    },
                },
                {
                    targets: -1,
                    data: null,
                    sortable: false,
                    searchable: false,
                    render: function(data, type, row) {
                        return `<a href="/residenze/dettaglio?id=${row[0]}" class="v-state button success">Vedi</a>`;
                    }
                },
            ],
        }
    },
    created() {
        
    },
    mounted() {
        
    },
    template: /*html*/`
        <div>
            <div class="flex items-center justify-between mb-6">
                <h1>RESIDENZE GESTITE</h1>
            </div>

            <data-table ajax="/api/residenze/data" :columns="columnsResidenze" :columnDefs="columnDefs"></data-table>
        </div>
    `
}