export default {
    template: /*html*/`
    <svg xmlns="http://www.w3.org/2000/svg" width="26.966" height="26.993" viewBox="0 0 26.966 26.993">
        <g id="Raggruppa_516" data-name="Raggruppa 516" transform="translate(-1326 -44)">
            <path id="Tracciato_132" data-name="Tracciato 132" d="M964.637,545.815a13.462,13.462,0,0,1-17.472-.027c.47-.265.864-.511,1.273-.735q1.674-.919,3.356-1.831a1.549,1.549,0,0,0,.9-1.273c.048-.585.027-1.178.02-1.77a.475.475,0,0,0-.116-.225,9.055,9.055,0,0,1-1.463-3.015.653.653,0,0,0-.184-.3,1.448,1.448,0,0,1-.49-1.123v-1.688a1.445,1.445,0,0,1,.32-.926.544.544,0,0,0,.116-.3c.014-.837.014-1.674,0-2.5a3.356,3.356,0,0,1,1.96-3.39,7.171,7.171,0,0,1,6.092-.007,3.343,3.343,0,0,1,1.967,3.287c.007.858-.014,1.722-.007,2.58a.606.606,0,0,0,.122.333,1.511,1.511,0,0,1,.32.926c0,.579.007,1.157,0,1.736a1.328,1.328,0,0,1-.762,1.239.608.608,0,0,0-.3.361c-.293.647-.592,1.286-.919,1.919a7.018,7.018,0,0,0-.694,1.177,6.183,6.183,0,0,0-.027,1.422,1.659,1.659,0,0,0,1.021,1.633C961.288,544.127,962.921,544.944,964.637,545.815Z" transform="translate(383.583 -478.028)" fill="#fff"/>
            <path id="Tracciato_133" data-name="Tracciato 133" d="M967.156,533.673c0,.177,0,.354-.014.524a11.214,11.214,0,0,1-.123,1.361A13.251,13.251,0,0,1,964.5,541.7a13.77,13.77,0,0,1-1.77,1.953.07.07,0,0,0-.041.034c-.1.089-.2.177-.3.259-1.715-.871-3.349-1.688-4.969-2.5a1.659,1.659,0,0,1-1.021-1.634,6.18,6.18,0,0,1,.027-1.423,7.012,7.012,0,0,1,.694-1.177c.327-.633.626-1.273.919-1.919a.608.608,0,0,1,.3-.361,1.329,1.329,0,0,0,.762-1.239c.007-.578,0-1.157,0-1.736a1.511,1.511,0,0,0-.32-.926.607.607,0,0,1-.122-.333c-.007-.858.014-1.722.007-2.58a3.343,3.343,0,0,0-1.967-3.287,7.171,7.171,0,0,0-6.092.007,3.356,3.356,0,0,0-1.96,3.389c.014.83.014,1.668,0,2.5a.545.545,0,0,1-.116.3,1.444,1.444,0,0,0-.32.926v1.688a1.447,1.447,0,0,0,.49,1.123.653.653,0,0,1,.184.3,9.057,9.057,0,0,0,1.463,3.015.476.476,0,0,1,.116.225c.007.592.027,1.184-.02,1.77a1.549,1.549,0,0,1-.9,1.273q-1.685.909-3.355,1.831c-.408.225-.8.47-1.273.735-.1-.082-.184-.157-.272-.238a.119.119,0,0,0-.034-.027,13.389,13.389,0,0,1-1.209-1.278c-.016-.02-.034-.037-.05-.056-.1-.116-.2-.238-.3-.361s-.19-.245-.279-.368c-.116-.15-.225-.3-.327-.456a13.434,13.434,0,0,1-2.1-5.391c-.027-.184-.054-.374-.068-.558s-.034-.368-.048-.551a3.574,3.574,0,0,1-.02-.381q-.02-.245-.02-.49v-.129a13.4,13.4,0,0,1,2.967-8.4c.075-.1.163-.2.245-.3a12.925,12.925,0,0,1,1.654-1.633c.055-.048.116-.1.17-.143a13.488,13.488,0,0,1,5.731-2.7c.2-.041.4-.075.606-.109.02,0,.041-.007.054-.007q.306-.051.613-.082a1.683,1.683,0,0,1,.177-.014q.286-.031.572-.041c.225-.014.456-.02.688-.02h.013c.232,0,.463.007.688.02.129.007.259.014.388.027.231.014.456.041.681.068l.136.021c.143.02.279.041.415.068.306.048.606.109.905.184l.449.122c.143.041.293.082.435.129.293.089.585.2.864.313.143.054.286.116.422.177a7.694,7.694,0,0,1,.83.395c.136.068.272.143.4.218a11.588,11.588,0,0,1,1.164.742,13.416,13.416,0,0,1,5.683,10.468C967.15,533.319,967.156,533.5,967.156,533.673Z" transform="translate(385.81 -476.19)" fill="#1a7fb5"/>
        </g>
    </svg>
    `
}
