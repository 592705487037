export default {
    template: /*html*/`
    <svg xmlns="http://www.w3.org/2000/svg" width="25.563" height="19" viewBox="0 0 25.563 19">
        <g id="Raggruppa_542" data-name="Raggruppa 542" transform="translate(81.35 -1628) rotate(90)">
            <line id="Linea_38" data-name="Linea 38" y1="22.563" transform="translate(1629.5 57.286)" fill="none" stroke="#707070" stroke-linecap="round" stroke-width="3"/>
            <line id="Linea_39" data-name="Linea 39" y1="22.563" transform="translate(1637.5 57.286)" fill="none" stroke="#707070" stroke-linecap="round" stroke-width="3"/>
            <line id="Linea_40" data-name="Linea 40" y1="22.563" transform="translate(1645.5 57.286)" fill="none" stroke="#707070" stroke-linecap="round" stroke-width="3"/>
        </g>
    </svg>
    `
}
