import PageResidenze from '../pages/residenze';
import PageResidenzeNuovo from '../pages/residenze-nuovo';
import ResidenzeDettaglio from '../pages/residenze-dettaglio';

import PageInterventiRichiesti from '../pages/interventi-richiesti';
import PageInterventiNuovo from '../pages/interventi-nuovo';
import PageInterventiDettaglio from '../pages/interventi-dettaglio';
import PageInterventiRealizzati from '../pages/interventi-realizzati';
import PageInterventiStorico from '../pages/interventi-storico';

import NotFound from '../pages/not-found';
import PageImpostazioni from '../pages/impostazioni';
import PageProfilo from '../pages/profilo';

const routes = {
    'void': {},

    // Residenze
    '/residenze': PageResidenze,
    //'/residenze/nuovo': PageResidenzeNuovo,
    '/residenze/dettaglio': ResidenzeDettaglio,

    // Interventi
    '/interventi-richiesti': PageInterventiRichiesti,
    '/interventi-richiesti/nuovo': PageInterventiNuovo,
    '/interventi/dettaglio': PageInterventiDettaglio,
    '/interventi-realizzati': PageInterventiRealizzati,
    '/interventi-storico': PageInterventiStorico,

    // Impostazioni
    '/impostazioni': PageImpostazioni,
    '/profilo': PageProfilo,
};

export default {
    name: 'router',
    data() {
        return {
            id: null,
            currentPath: window.location.pathname,
            currentState: {
                id: null,
                url: "",
            },
        }
    },
    computed: {
        currentView() {
            return routes[this.currentPath] || NotFound;
        },

        currentStateView() {
            if (this.currentState.url != "") {
                return routes[this.currentState.url] || NotFound;
            }

            return null;
        },
    },
    mounted() {
        window.addEventListener('pushstate', (e) => {
            if (e.detail) {
                if (e.detail.reload) {
                    this.currentPath = 'void';
                    setTimeout(() => {
                        this.currentPath = window.location.pathname;
                    }, 0);
                }
            }
            else {
                this.currentPath = window.location.pathname;
            }
        });

        window.addEventListener('changestate', (e) => {
            if (e.detail != "") {
                this.currentState.url = window.location.pathname;
            }
            else {
                this.currentState.url = "";
            }
        });
    },
    template: /*html*/`
        <div class="page-view page-content" :class="{'hidden': currentState.url != ''}">
            <component :is="currentView" :id="id" />
        </div>
        <div class="state-view page-content" :class="{'hidden': currentState.url == ''}">
            <component :is="currentStateView" :id="currentState.id" />
        </div>
    `,
}
