// Checkbox component
export default {
    props: ['name', 'type', 'id', 'label', 'value', 'required', 'disabled', 'readonly', 'autofocus', 'error', 'className'],
    methods: {
        onclick: function(e) {
            // Trigger click on input
            this.$refs.input.click();
        },
    },
    template: /*html*/`
        <div class="inline-block">
            <label :for="name" class="text-sm font-medium text-gray-700" v-if="label">{{ label }}</label>

            <div class="input-checkbox" @click="onclick">
                <div class="checkbox">
                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="5.22" viewBox="0 0 7 5.22">
                        <path id="Check" d="M2.378,70.216.1,67.941a.35.35,0,0,1,0-.495L.6,66.951a.35.35,0,0,1,.495,0l1.533,1.533L5.908,65.2a.35.35,0,0,1,.495,0L6.9,65.7a.35.35,0,0,1,0,.495L2.873,70.216a.35.35,0,0,1-.495,0Z" transform="translate(0 -65.098)" fill="#fff"/>
                    </svg>
                </div>

                <input ref="input" :name="name"
                    :id="id"
                    :value="value"
                    :required="required"
                    :disabled="disabled"
                    :readonly="readonly"
                    :autofocus="autofocus"
                    type="checkbox"
                    class="input-checkbox"
                    :class="className" />
            </div>

        </div>
    `
}