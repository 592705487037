// <select> element
export default {
    props: ['name', 'label', 'value', 'placeholder', 'required', 'disabled', 'readonly', 'autocomplete', 'autofocus', 'multiple', 'size', 'error', 'className', 'items'],
    template: /*html*/`
        <div class="flex flex-col">
            <label :for="name" class="mb-2 text-sm font-medium text-gray-700" v-if="label">{{ label }}</label>
            <select :name="name"
                :id="name"
                :value="value"
                :placeholder="placeholder"
                :required="required"
                :disabled="disabled"
                :readonly="readonly"
                :autocomplete="autocomplete"
                :autofocus="autofocus"
                :multiple="multiple"
                :size="size"
                class="input-select"
                :class="className">
                <option v-if="!required"></option>
                <option v-for="item in items" :value="item.value">{{ item.text }}</option>
            </select>
        </div>
    `
}