import XLink from '../components/link';
import XForm from '../components/form';
import InputText from '../components/input-text';
import InputSelect from '../components/input-select';
import XButton from '../components/button';
import XLoader from '../components/loader';
import XModal from '../components/modal';

export default {
    name: 'interventi-nuovo',
    data() {
        return {
            residenze: [],
            urgenze: props.db.urgenze.map((urgenza) => {
                return {
                    value: urgenza.key,
                    text: urgenza.value,
                }
            }),
            loader: false,
            modal: {
                id: 'modal-interventi-nuovo',
                show: false,
                message: '',
            },
            isSuccess: false,
        }
    },
    components: {
        XLink,
        XForm,
        InputText,
        InputSelect,
        XButton,
        XLoader,
        XModal,
    },
    methods: {
        onSubmit: function({ e, elements }) {
            this.loader = true;
        },
        onSuccess: function({ response }) {
            this.loader = false;

            const data = response.data;

            this.modal.message = data.message;
            this.modal.show = true;
            this.isSuccess = data.response;

            if (data.response) {
                this.$refs.form.querySelector('form').reset();
            }
        },
        onError: function({ error }) {
            this.loader = false;

            this.modal.message = error.message;
            this.modal.show = true;
            this.isSuccess = false;
        },
        onModalClose: function() {
            this.modal.show = false;

            if (this.isSuccess) {
                window.app.navigate('/interventi-richiesti', {
                    reload: true
                });
            }
        },
    },
    mounted: function() {
        this.loader = true;

        axios.get('/api/residenze')
            .then((response) => {
                const data = response.data;

                this.residenze = data.map((residenza) => {
                    return {
                        value: residenza.codLuogo,
                        text: residenza.Nome + ' - ' + residenza.Indirizzo,
                    }
                });

                this.loader = false;
            })
            .catch((error) => {
                this.loader = false;

                this.modal.message = error.message;
                this.modal.show = true;
            });
    },
    template: /*html*/`
        <div>
            <div class="mb-12 py-4">
                <h1>
                    <x-link href="/interventi-richiesti" class="v-state-back">INTERVENTI RICHIESTI</x-link> > <b>NUOVO</b>
                </h1>
            </div>

            <div ref="form">
                <x-form action="/api/interventi" method="post" v-on:form-submit="onSubmit" v-on:success="onSuccess" v-on:error="onError">
                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                            <div class="form-group" data-title="Dati intervento">

                                <input-select name="residenza"
                                    label="Residenza"
                                    className="small"
                                    required="true"
                                    :items="residenze" />

                                <input-select name="urgenza"
                                    label="Urgenza"
                                    className="small"
                                    required="true"
                                    :items="urgenze" />

                                <input-text name="dove"
                                    type="text"
                                    placeholder="Inserisci il luogo"
                                    label="Luogo"
                                    required="true"
                                    className="small" />

                                <input-text name="cosa"
                                    type="textarea"
                                    placeholder="Inserisci la descrizione dell'intervento da svolgere"
                                    label="Descrizione"
                                    required="true"
                                    rows="5"
                                    className="small" />

                                <x-button color="primary"
                                    type="submit">Invia richiesta</x-button>

                            </div>
                        </div>
                    </div>
                </x-form>
            </div>

            <x-modal :id="modal.id" :show="modal.show" v-on:close="onModalClose">
                <div class="flex flex-col items-center justify-center">
                    <p class="text-center">{{ modal.message }}</p>

                    <div class="mt-8">
                        <x-button color="primary" class="min-w-[15rem]" v-on:click="onModalClose">Ok</x-button>
                    </div>
                </div>
            </x-modal>
            <x-loader :show="loader"></x-loader>
        </div>
    `,
}