import InputText from '../components/input-text';
import InputSelect from '../components/input-select';
import GroupCheckbox from '../components/group-checkbox';
import GroupRadio from '../components/group-radio';
import InputFiles from '../components/input-files';

export default {
    name: 'impostazioni',
    components: {
        InputText,
        InputSelect,
        GroupCheckbox,
        GroupRadio,
        InputFiles,
    },
    template: /*html*/`
        <div>
            <h1 class="h6 mb-20">Impostazioni</h1>

            <div class="form-group" data-title="Form elements">
                <input-text name="nome" 
                    placeholder="Inserisci il tuo nome"
                    label="Nome"
                    className="small" />

                <input-text name="email"
                    type="email"
                    placeholder="Inserisci il tuo indirizzo e-mail"
                    label="E-mail"
                    className="small" />

                <input-text name="password"
                    type="password"
                    placeholder="Inserisci la tua password"
                    label="Password"
                    className="small" />

                <input-text name="messaggio"
                    type="textarea"
                    placeholder="Inserisci il tuo messaggio"
                    label="Messaggio"
                    rows="5"
                    className="small" />

                <input-select name="select"
                    label="Select"
                    className="small"
                    :items="[
                        { value: '1', text: 'Option 1' },
                        { value: '2', text: 'Option 2' },
                        { value: '3', text: 'Option 3' },
                    ]" />

                <input-select name="select"
                    label="Select"
                    className="small"
                    multiple="true"
                    :items="[
                        { value: '1', text: 'Option 1' },
                        { value: '2', text: 'Option 2' },
                        { value: '3', text: 'Option 3' },
                    ]" />

                <group-checkbox name="opzioni"
                    label="Checkbox"
                    :items="[
                        { value: '1', text: 'Option 1' },
                        { value: '2', text: 'Option 2' },
                        { value: '3', text: 'Option 3' },
                    ]" />

                <group-checkbox name="opzioni2"
                    label="Checkbox inline"
                    className="inline"
                    :items="[
                        { value: '1', text: 'Option 1' },
                        { value: '2', text: 'Option 2' },
                        { value: '3', text: 'Option 3' },
                    ]" />

                <group-radio name="opzioni-radio"
                    label="Radio"
                    :items="[
                        { value: '1', text: 'Option 1' },
                        { value: '2', text: 'Option 2' },
                        { value: '3', text: 'Option 3' },
                    ]" />

                <group-radio name="opzioni-radio2"
                    label="Radio inline"
                    className="inline"
                    :items="[
                        { value: '1', text: 'Option 1' },
                        { value: '2', text: 'Option 2' },
                        { value: '3', text: 'Option 3' },
                    ]" />

                <group-checkbox name="opzioni-switch"
                    type="switch"
                    label="Switch"
                    :items="[
                        { value: '1', text: 'Option 1' },
                        { value: '2', text: 'Option 2' },
                        { value: '3', text: 'Option 3' },
                    ]" />

                <group-checkbox name="opzioni-switch-2"
                    type="switch"
                    label="Switch inline"
                    className="inline"
                    :items="[
                        { value: '1', text: 'Option 1' },
                        { value: '2', text: 'Option 2' },
                        { value: '3', text: 'Option 3' },
                    ]" />

                <input-files name="file" />
            </div>
        </div>
    `,
}