import inputCheckbox from "./input-checkbox";

export default {
    props: ['name', 'type', 'label', 'value', 'required', 'disabled', 'readonly', 'autofocus', 'error', 'className', 'items'],
    components: {
        inputCheckbox,
    },
    template: /*html*/`
        <div class="flex flex-col group-checkbox">
            <label :for="name" class="mb-2 text-sm font-medium text-gray-700" v-if="label">{{ label }}</label>
            <div class="flex flex-col" :class="className">
                <div v-for="item in items" class="flex items-center gap-2">
                    <input-checkbox :name="name+'[]'"
                        :id="name+'-'+item.value"
                        :value="item.value"
                        :required="required"
                        :disabled="disabled"
                        :readonly="readonly"
                        :autofocus="autofocus"
                        :className="type" />
                    <label :for="name+'-'+item.value" class="text-sm font-light text-gray-700" v-if="label">{{ item.text }}</label>
                </div>
            </div>
        </div>
    `
}