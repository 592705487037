import Button from './button';
import Link from './link';

export default {
    props: ['items'],
    components: {
        Button,
        Link,
    },
    data() {
        return {
            showDropdown: false,
        }
    },
    methods: {
        toggleDropdown: function() {
            this.showDropdown = !this.showDropdown;
            this.$emit('toggle');

            if (this.showDropdown) {
                this.$emit('open');
            }
            else {
                this.$emit('close');
            }
        }
    },
    mounted() {
        // Close dropdown when clicking outside the dropdown
        document.addEventListener('click', (e) => {
            if (!this.$el.contains(e.target)) {
                this.showDropdown = false;
            }
        });
    },
    template: /*html*/`
        <div ref="dropdown" class="relative">
            <Button color="light" @click="toggleDropdown">
                <slot></slot>
            </Button>

            <div class="dropdown" :class="{
                'show': showDropdown,
            }">
                <div class="dropdown-menu">
                    <div class="dropdown-menu-item" v-for="item in items" @click="toggleDropdown">
                        <Link :href="item.url" class="dropdown-menu-link">{{ item.title }}</Link>
                    </div>
                </div>
            </div>
        </div>
    `
}