export default {
    template: /*html*/`
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="7" viewBox="0 0 31 7">
        <g id="Raggruppa_513" data-name="Raggruppa 513" transform="translate(-1622 -54)">
            <circle id="Ellisse_19" data-name="Ellisse 19" cx="3.5" cy="3.5" r="3.5" transform="translate(1634 54)" fill="#707070"/>
            <circle id="Ellisse_18" data-name="Ellisse 18" cx="3.5" cy="3.5" r="3.5" transform="translate(1622 54)" fill="#707070"/>
            <circle id="Ellisse_20" data-name="Ellisse 20" cx="3.5" cy="3.5" r="3.5" transform="translate(1646 54)" fill="#707070"/>
        </g>
    </svg>
    `
}
