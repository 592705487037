import axios from "axios";

export default {
    name: 'form',
    props: ['form', 'action', 'method', 'enctype', 'autocomplete', 'novalidate', 'target', 'name', 'id', 'class', 'style'],
    methods: {
        onSubmit: function(e) {
            e.preventDefault();
            e.stopPropagation();

            const elements = e.target.elements;

            this.$emit('formSubmit', { e, elements });

            axios({
                method: this.method,
                url: this.action,
                data: new FormData(e.target),
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                this.$emit('success', { response, elements });
                this.$emit('complete', { response, elements });
            })
            .catch((error) => {
                this.$emit('error', { error });
                this.$emit('complete', { error });
            });
        },
    },
    template: /*html*/`
        <form @submit="onSubmit" :method="method" :action="action">
            <slot></slot>
        </form>
    `,
}