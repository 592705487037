export default {
    props: ["title", "link"],
    template: /*html*/`
    <a :href="link" target="_blank" class="border-b-[1px] border-b-black/5 last:border-b-0">
        <div class="flex justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="90"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM216 232V334.1l31-31c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-72 72c-9.4 9.4-24.6 9.4-33.9 0l-72-72c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l31 31V232c0-13.3 10.7-24 24-24s24 10.7 24 24z" fill="#209BD8" /></svg>
        </div>
        <div class="p-4 px-4 w-[18rem] font-bold flex-shrink-0 text-center">{{ title }}</div>
    </a>
    `
}