import XLink from '../components/link';
import XForm from '../components/form';
import InputText from '../components/input-text';
import InputSelect from '../components/input-select';
import XButton from '../components/button';
import XLoader from '../components/loader';
import XModal from '../components/modal';

export default {
    name: 'residenze-nuovo',
    data() {
        return {
            comuni: props.db.comuni.map((comune) => {
                return {
                    value: comune.codComune,
                    text: comune.Nome,
                }
            }),
            loader: false,
            modal: {
                id: 'modal-residenze-nuovo',
                show: false,
                message: '',
            },
            isSuccess: false,
        }
    },
    components: {
        XLink,
        XForm,
        InputText,
        InputSelect,
        XButton,
        XLoader,
        XModal,
    },
    methods: {
        onSubmit: function({ e, elements }) {
            this.loader = true;
        },
        onSuccess: function({ response }) {
            this.loader = false;

            const data = response.data;

            this.modal.message = data.message;
            this.modal.show = true;
            this.isSuccess = data.response;

            if (data.response) {
                this.$refs.form.querySelector('form').reset();
            }
        },
        onError: function({ error }) {
            this.loader = false;

            this.modal.message = error.message;
            this.modal.show = true;
            this.isSuccess = false;
        },
        onModalClose: function() {
            this.modal.show = false;

            if (this.isSuccess) {
                window.app.navigate('/residenze', {
                    reload: true
                });
            }
        },
    },
    template: /*html*/`
        <div>
            <div class="mb-12 py-4">
                <h1>
                    <x-link href="/residenze" class="v-state-back">RESIDENZE GESTITE</x-link> > <b>NUOVO</b>
                </h1>
            </div>

            <div ref="form">
                <x-form action="/api/residenze" method="post" v-on:form-submit="onSubmit" v-on:success="onSuccess" v-on:error="onError">
                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                            <div class="form-group" data-title="Dati residenza">
                                <input-text name="nome" 
                                    placeholder="Inserisci il nome"
                                    label="Nome"
                                    required="true"
                                    className="small" />

                                <input-text name="indirizzo"
                                    type="text"
                                    placeholder="Inserisci l'indirizzo"
                                    label="Indirizzo"
                                    required="true"
                                    className="small" />

                                <input-text name="link-google"
                                    type="text"
                                    placeholder="Inserisci il link di Google Maps"
                                    label="Link Google Maps"
                                    required="true"
                                    className="small" />

                                <input-text name="lat"
                                    type="text"
                                    placeholder="Inserisci la latitudine"
                                    label="Latitudine"
                                    required="true"
                                    className="small" />

                                <input-text name="lng"
                                    type="text"
                                    placeholder="Inserisci la longitudine"
                                    label="Longitudine"
                                    required="true"
                                    className="small" />

                                <input-select name="comune"
                                    label="Comune"
                                    className="small"
                                    required="true"
                                    :items="comuni" />

                                <input-text name="codice-fiscale"
                                    type="text"
                                    placeholder="Inserisci il codice fiscale"
                                    label="Codice Fiscale"
                                    required="true"
                                    className="small" />

                                <input-text name="codice-cliente"
                                    type="text"
                                    placeholder="Inserisci il codice cliente"
                                    label="Codice Cliente"
                                    required="true"
                                    className="small" />

                                <input-text name="codice-produttore"
                                    type="text"
                                    placeholder="Inserisci il codice produttore"
                                    label="Codice Produttore"
                                    className="small" />
                                
                                <x-button color="primary"
                                    type="submit">Salva</x-button>

                            </div>
                        </div>
                    </div>
                </x-form>
            </div>

            <x-modal :id="modal.id" :show="modal.show" v-on:close="onModalClose">
                <div class="flex flex-col items-center justify-center">
                    <p class="text-center">{{ modal.message }}</p>

                    <div class="mt-8">
                        <x-button color="primary" class="min-w-[15rem]" v-on:click="onModalClose">Ok</x-button>
                    </div>
                </div>
            </x-modal>
            <x-loader :show="loader"></x-loader>
        </div>
    `,
}