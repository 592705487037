import InputText from '../components/input-text';
import XButton from '../components/button';
import XForm from '../components/form';
import XLoader from '../components/loader';
import XModal from '../components/modal';
import axios from 'axios';

export default {
    name: 'impostazioni',
    components: {
        InputText,
        XButton,
        XForm,
        XLoader,
        XModal,
    },
    data() {
        return {
            loader: false,
            modal: {
                id: 'modal-profilo',
                show: false,
                message: '',
            },
            isSuccess: false,
            data: {},
        }
    },
    created() {
        this.data = {
            nome: props.user.nome,
            cognome: props.user.cognome,
            email: props.user.email,
            password: '',
            password2: '',
        }
    },
    methods: {
        onSubmit: function({ e, elements }) {
            this.loader = true;
        },
        onSuccess: function({ response, elements }) {
            this.loader = false;

            const data = response.data;

            this.modal.message = data.message;
            this.modal.show = true;
            this.isSuccess = data.response;

            // Update the props.user object
            app.user.nome = elements.nome.value;
            app.user.cognome = elements.cognome.value;
            app.user.email = elements.email.value;
        },
        onError: function({ error }) {
            this.loader = false;

            this.modal.message = error.message;
            this.modal.show = true;
            this.isSuccess = false;
        },
        onModalClose: function() {
            this.modal.show = false;
        },
    },
    template: /*html*/`
        <div>
            <h1 class="h6 mb-20">Profilo</h1>

            <x-form action="/api/user/update" method="post" v-on:form-submit="onSubmit" v-on:success="onSuccess" v-on:error="onError">
                <div class="form-group" data-title="Dati personali">
                    <input-text name="nome" 
                        placeholder="Inserisci il tuo nome"
                        label="Nome"
                        required="true"
                        :value="data.nome"
                        className="small" />

                    <input-text name="cognome" 
                        placeholder="Inserisci il tuo cognome"
                        label="Cognome"
                        required="true"
                        :value="data.cognome"
                        className="small" />

                    <input-text name="email"
                        type="email"
                        placeholder="Inserisci il tuo indirizzo e-mail"
                        label="E-mail"
                        required="true"
                        :value="data.email"
                        className="small" />

                    <input-text name="password"
                        type="password"
                        placeholder="Inserisci la tua password"
                        label="Password"
                        :value="data.password"
                        className="small" />

                    <input-text name="password2"
                        type="password"
                        placeholder="Ripeti la nuova password"
                        label="Ripeti password"
                        :value="data.password2"
                        className="small" />

                    <x-button color="primary"
                        type="submit">Salva</x-button>
                </div>
            </x-form>

            <x-modal :id="modal.id" :show="modal.show" v-on:close="onModalClose">
                <div class="flex flex-col items-center justify-center">
                    <p class="text-center">{{ modal.message }}</p>

                    <div class="mt-8">
                        <x-button color="primary" class="min-w-[15rem]" v-on:click="onModalClose">Ok</x-button>
                    </div>
                </div>
            </x-modal>
            <x-loader :show="loader"></x-loader>
        </div>
    `,
}