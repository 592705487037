// Radio component
export default {
    props: ['name', 'id', 'label', 'value', 'required', 'disabled', 'readonly', 'autofocus', 'error', 'className'],
    methods: {
        onclick: function(e) {
            // Trigger click on input
            this.$refs.input.click();
        },
    },
    template: /*html*/`
        <div class="inline-block">
            <label :for="name" class="text-sm font-medium text-gray-700" v-if="label">{{ label }}</label>

            <div class="input-radio" @click="onclick">
                <div class="radio"></div>

                <input ref="input" :name="name"
                    :id="id"
                    :value="value"
                    :required="required"
                    :disabled="disabled"
                    :readonly="readonly"
                    :autofocus="autofocus"
                    type="radio"
                    class="input-radio"
                    :class="className" />
            </div>

        </div>
    `
}