import MenuItem from './menu-item';

export default {
    props: ['items'],
    components: {
        MenuItem
    },
    methods: {
        onClick: function(items, item) {
            // Set all items to inactive
            items.forEach(item => {
                item.active = false;
            });

            item.active = true;
        }
    },
    template: /*html*/`
        <div class="flex flex-col items-center">
            <MenuItem v-for="item in items" :item="item" @click="onClick(items, item)" />
        </div>
    `
}