export default {
    name: 'loader',
    props: ['show'],
    data() {
        return {
            showLoader: this.show,
        }
    },
    watch: {
        show: function(val) {
            this.showLoader = val;
        }
    },
    template: /*html*/`
        <div>
            <div class="loader" :class="{ show: showLoader }">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <circle cx="50" cy="50" fill="none" stroke="#ffffff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                    </circle>
                </svg>
            </div>
        </div>
    `,
}