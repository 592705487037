import Link from './link';

export default {
    props: ['item'],
    components: {
        Link
    },
    template: /*html*/`
        <Link :href="item.url" className="w-full">
            <div class="flex items-center justify-between w-full p-7 border-b-[1px] border-b-light/20 text-tiny transition-all duration-fast text-light" :class="{
                'font-light': !item.active,
                'font-medium': item.active,
                'bg-gray-100/20': item.active,
                'hover:bg-gray-100/20': !item.active
            }">
                <span>{{ item.title }}</span>
                <span class="w-4 h-4 rounded-full ring-1 ring-light" :class="{
                    'bg-light': item.active,
                    'ring-light': item.active
                }"></span>
            </div>
        </Link>
    `
}