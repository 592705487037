const Vue = require('vue');
import axios from 'axios';

window.axios = axios;

import XButton from './components/button';
import XImage from './components/image';
import XLink from './components/link';
import SearchBox from './components/search-box';
import InputText from './components/input-text';
import MenuList from './components/menu-list';
import Dropdown from './components/dropdown';
import router from './components/router';
import XForm from './components/form';
import XModal from './components/modal';
import XLoader from './components/loader';

import IconOptions from './icons/options';
import IconNotify from './icons/notify';
import IconAccount from './icons/account';
import IconArrowDown from './icons/arrow-down';
import IconHamburger from './icons/hamburger';

window.app = Vue.createApp({
    delimiters: ['${', '}'],
    components: {
        XButton,
        XImage,
        XLink,
        SearchBox,
        InputText,
        MenuList,
        Dropdown,
        XForm,
        XModal,
        XLoader,

        IconOptions,
        IconNotify,
        IconAccount,
        IconArrowDown,
        IconHamburger,

        router,
    },
    data() {
        return {
            asideOpen: false,
            state: "",
            modalSession: {
                id: 'modal-session',
                show: false,
                message: '',
            },
            ...window.props
        }
    },
    mounted() {
        document.addEventListener('click', function(e) {
            e.path.forEach((el) => {
                // If matches exist in el
                if (el.matches) {
                    if (el.matches('.v-link')) {
                        e.preventDefault();
                        const href = el.getAttribute('href');

                        window.app.navigate(href);
                    }
                    else if (el.matches('.v-state')) {
                        e.preventDefault();
                        const href = el.getAttribute('href');

                        window.app.changeState(href);
                    }
                    else if (el.matches('.v-state-back')) {
                        e.preventDefault();
                        const href = el.getAttribute('href');

                        if (this.state == null) {
                            window.app.navigate(href);
                        }

                        window.app.changeState("");
                    }
                }
            });
        });

        setInterval(() => {
            axios.get('/api/session')
            .then(response => {
                this.modalSession.show = !response.data.response;
                this.modalSession.message = response.data.message;
            })
            .catch(error => {
                console.log(error);
            });
        }, 30 * 1000);
    },
    methods: {
        navigate: function(href, options) {
            // Navigate with history API
            window.history.pushState({}, '', href);
            window.app.changeState("");

            window.dispatchEvent(new CustomEvent('pushstate', {
                'detail': options
            }));
        },
        changeState: function(url) {
            this.state = url;

            window.history.pushState({}, '', url);

            window.dispatchEvent(new CustomEvent('changestate', {
                'detail': url
            }));
        },
        modalSessionConfirm: function() {
            this.modalSession.show = false;

            window.location.href = "/logout";
        },
        onModalSessionClose: function() {
            this.modalSession.show = false;

            window.location.href = "/logout";
        },
        ...window.methods
    }
}).mount('#app');