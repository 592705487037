import XLink from '../components/link';
import XForm from '../components/form';
import InputText from '../components/input-text';
import InputFile from '../components/input-files';
import InputSelect from '../components/input-select';
import XButton from '../components/button';
import XLoader from '../components/loader';
import XModal from '../components/modal';
import ListFile from '../components/listfile';
import ListFileItem from '../components/listfile-item';
import axios from 'axios';

export default {
    name: 'residenze-dettaglio',
    data() {
        return {
            comuni: props.db.comuni.map((comune) => {
                return {
                    value: comune.codComune,
                    text: comune.Nome,
                }
            }),
            loader: false,
            modal: {
                id: 'modal-residenze-dettaglio',
                show: false,
                message: '',
            },
            modal_file: {
                id: 'modal-residenze-file-dettaglio',
                show: false,
                message: '',
            },
            isSuccess: false,
            props: {},
            data: [],
        }
    },
    components: {
        XLink,
        XForm,
        InputFile,
        InputText,
        InputSelect,
        XButton,
        XLoader,
        XModal,
        ListFile,
        ListFileItem,
    },
    created() {
        this.props = getUrlParams();
    },
    mounted() {
        this.loader = true;

        axios({
            method: 'GET',
            url: '/api/residenze/' + this.props.id,
        })
        .then((response) => {
            this.data = response.data;
            this.loader = false;
        })
        .catch((error) => {
            console.log(error);
            this.loader = false;
        });
    },
    methods: {
        onSubmit: function({ e, elements }) {
            this.loader = true;
        },
        onSuccess: function({ response }) {
            this.loader = false;

            const data = response.data;

            this.modal.message = data.message;
            this.modal.show = true;
            this.isSuccess = data.response;
        },
        onError: function({ error }) {
            this.loader = false;

            this.modal.message = error.message;
            this.modal.show = true;
            this.isSuccess = false;
        },
        onSuccessFile: function({ response }) {
            this.loader = false;

            const data = response.data;

            this.modal_file.message = data.message;
            this.modal_file.show = true;
            this.isSuccess = data.response;
        },
        onModalClose: function() {
            this.modal.show = false;

            if (this.isSuccess) {
                window.app.navigate('/residenze', {
                    reload: true
                });
            }
        },
        onModalCloseFile: function() {
            this.modal_file.show = false;

            if (this.isSuccess) {
                window.location.reload();
            }
        },
    },
    template: /*html*/`
        <div>
            <div class="mb-12 py-4">
                <h1>
                    <x-link href="/residenze" class="v-link">RESIDENZE GESTITE</x-link> > <b>DETTAGLIO</b>
                </h1>
            </div>

            <div ref="form">
                <x-form :action="'/api/residenze/' + data.codLuogo" method="post" v-on:form-submit="onSubmit" v-on:success="onSuccess" v-on:error="onError">
                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                            <div class="form-group" data-title="Dati residenza">
                                <input-text name="nome" 
                                    label="Nome"
                                    required="true"
                                    readonly="true"
                                    :value="data.Nome"
                                    className="small" />

                                <input-text name="indirizzo"
                                    type="text"
                                    label="Indirizzo"
                                    required="true"
                                    readonly="true"
                                    :value="data.Indirizzo"
                                    className="small" />

                                <input-text name="link-google"
                                    type="text"
                                    label="Link Google Maps"
                                    required="true"
                                    readonly="true"
                                    :value="data.LinkGoogle"
                                    className="small" />

                                <input-text name="lat"
                                    type="text"
                                    label="Latitudine"
                                    required="true"
                                    readonly="true"
                                    :value="data.Lat"
                                    className="small" />

                                <input-text name="lng"
                                    type="text"
                                    label="Longitudine"
                                    required="true"
                                    readonly="true"
                                    :value="data.Lng"
                                    className="small" />

                                <input-select name="comune"
                                    label="Comune"
                                    className="small"
                                    required="true"
                                    readonly="true"
                                    :value="data.codComune"
                                    :items="comuni" />

                                <input-text name="codice-fiscale"
                                    type="text"
                                    label="Codice Fiscale"
                                    required="true"
                                    readonly="true"
                                    :value="data.CodiceFiscale"
                                    className="small" />

                            </div>
                        </div>
                    </div>
                </x-form>
            </div>

            <div ref="form" v-if="data.codLuogo">
                <x-form :action="'/api/residenze/file/' + data.codLuogo" method="post" v-on:form-submit="onSubmit" v-on:success="onSuccessFile" v-on:error="onError" enctype="multipart/form-data">
                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                            <div class="form-group" data-title="Allegati">

                                <input-file name="allegati[]"
                                    label="Carica un file"
                                    className="small"
                                    required="true" />

                                <x-button color="primary"
                                    type="submit">Carica file</x-button>

                            </div>
                        </div>
                    </div>
                </x-form>
            </div>

            <div class="mb-12" v-if="data.codLuogo && data.Files.length > 0">
                <div>
                    <list-file>
                        <div v-for="allegato in data.Files">
                            <list-file-item
                                :title="allegato.Descrizione"
                                :link="allegato.Url" />
                        </div>
                    </list-file>
                </div>
            </div>

            <x-modal :id="modal.id" :show="modal.show" v-on:close="onModalClose">
                <div class="flex flex-col items-center justify-center">
                    <p class="text-center">{{ modal.message }}</p>

                    <div class="mt-8">
                        <x-button color="primary" class="min-w-[15rem]" v-on:click="onModalClose">Ok</x-button>
                    </div>
                </div>
            </x-modal>
            <x-modal :id="modal_file.id" :show="modal_file.show" v-on:close="onModalCloseFile">
                <div class="flex flex-col items-center justify-center">
                    <p class="text-center">{{ modal_file.message }}</p>

                    <div class="mt-8">
                        <x-button color="primary" class="min-w-[15rem]" v-on:click="onModalCloseFile">Ok</x-button>
                    </div>
                </div>
            </x-modal>
            <x-loader :show="loader"></x-loader>
        </div>
    `
}