export default {
    props: ['name', 'type', 'id', 'label', 'required', 'disabled', 'readonly', 'autofocus', 'error', 'className'],
    data() {
        return {
            value: null
        }
    },
    methods: {
        onclick: function(e) {
            // Trigger click on input
            this.$refs.input.click();
        },
        onchange: function(e) {
            this.value = e.target.value.replace(/^.*[\\\/]/, '');
        }
    },
    template: /*html*/`
        <div class="input-files" @click="onclick">
            <div class="input-files__button">Sfoglia...</div>
            <div class="input-files__label">
                <span>{{ value ? value : 'Nessun file selezionato' }}</span>
            </div>

            <input ref="input" type="file" class="input-files__input" :name="name" multiple="multiple" @change="onchange" />
        </div>
    `
}