import DataTable from 'datatables.net-vue3';
import 'datatables.net-dt/css/jquery.dataTables.css';

export default {
    name: 'datatable',
    components: {
        DataTable,
    },
    props: ['data', 'options', 'ajax', 'columns', 'columnDefs'],
    data() {
        return {
            options: {
                asStripeClasses: [],
                lengthMenu: [[10, 50, 100], [10, 50, 100]],
                language: {
                    searchPlaceholder: "Cerca...",
                    search: "",
                    lengthMenu: "Mostra _MENU_ elementi per pagina",
                    zeroRecords: "Nessun elemento trovato",
                    info: "Pagina _PAGE_ di _PAGES_",
                    infoEmpty: "Nessun elemento disponibile",
                    infoFiltered: "(filtrati da _MAX_ elementi totali)",
                    paginate: {
                        first: "Prima",
                        last: "Ultima",
                        next: "Prossima",
                        previous: "Precedente",
                    },
                },
                columnDefs: this.columnDefs,
            },
        }
    },
    template: /*html*/`
    <DataTable ref="table" :ajax="ajax" :options="options" class="display">
        <thead>
            <tr>
                <th v-for="item in columns">{{ item }}</th>
                <th></th>
            </tr>
        </thead>
    </DataTable>
    `
}