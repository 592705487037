import InputSearch from "./input-search";

export default {
    props: ['text'],
    components: {
        InputSearch
    },
    template: /*html*/`
        <div class="px-6 py-8 bg-gradient-to-r from-primary-400 to-primary-300">
            <InputSearch placeholder="Cerca..." />
        </div>
    `
}