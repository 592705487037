import SearchSVG from '../icons/search';

export default {
    props: ['name', 'label', 'value', 'placeholder', 'type', 'required', 'disabled', 'readonly', 'autocomplete', 'autofocus', 'maxlength', 'minlength', 'pattern', 'size', 'step', 'error'],
    components: {
        SearchSVG,
    },
    template: /*html*/`
        <div class="flex relative">
            <div class="absolute top-0 left-7 bottom-0 flex items-center"
                @click="this.$refs.input.focus()">
                <SearchSVG class="max-w-[1.8rem]" />
            </div>
            <input ref="input" :name="name" :value="value" :placeholder="placeholder" type="text" :required="required" :disabled="disabled" :readonly="readonly" :autocomplete="autocomplete" :autofocus="autofocus" :maxlength="maxlength" :minlength="minlength" :pattern="pattern" :size="size" :step="step" class="input-search !pl-20" />
        </div>
    `
}